import { LitElement, css, html } from 'lit';
import { Task } from '@lit/task';
import { customElement, property } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { type HttpClient, clientContext } from 'context/client-context';

import '../../pli/pli-text';
import '../../pli/pli-card';
import '../../pli/pli-dot-label';
import '../../pli/pli-donut-chart';
import '../../pli/pli-skeleton';

import { baseStyles, flexStyles, gridStyles } from '../../pli/index';
import { PliDotVariant } from '../../pli/pli-dot-label';
import { errorServiceContext } from 'context/error-service-context';
import { ErrorService } from 'error/error-service';
import { map } from 'lit/directives/map.js';
import { range } from 'lit/directives/range.js';

interface AlertsStatistics {
    new: number;
    opened: number;
    escalated: number;
    closed: number;
    falsePositive: number;
}

@customElement('dashboard-hero-graphics')
class DashboardHeroGraphics extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client?: HttpClient;

    @consume({ context: errorServiceContext, subscribe: true })
    @property({ attribute: false })
    public errorService?: ErrorService;

    private _todosTask = new Task(this, {
        task: async ([]) => {
            const response = await this.client.get('dashboard/alerts-statistics');
            const json = await response.json();
            return json as AlertsStatistics;
        },
        args: () => [],
    });

    static styles = [
        baseStyles,
        gridStyles,
        flexStyles,
        css`
            .graphics-grid {
                --donut-chart-size: 26rem;
                display: grid;
                grid-template-columns: var(--donut-chart-size) 1fr;
            }

            .chart {
                --gradient-start: rgba(199, 238, 225, 0.1);
                --gradient-end: rgba(137, 146, 211, 0.1);
                padding: var(--size-2);
                background: linear-gradient(180deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
            }

            .bar-chart {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: var(--size-2);
                gap: var(--size-1);
            }

            .bar-section {
                display: grid;
                grid-template-columns: 160px 1fr;
                align-items: center;
                gap: var(--size-1);
                --text-content-width: var(--size-4);
                --width: calc(var(--bar-width) - var(--text-content-width));
            }

            .bar {
                height: var(--size-1);
                display: flex;
                align-items: center;
            }

            @keyframes widthSlide {
                from {
                    width: 0;
                }

                to {
                    width: var(--width);
                }
            }

            .bar-value {
                height: inherit;
                min-width: var(--size-1);
                border-radius: var(--size-1);
                width: var(--width);
                background-color: var(--bar-bg);
                flex-shrink: 0;
                animation: widthSlide 2000ms cubic-bezier(0.06, 0.98, 0.19, 1) forwards 1;
            }

            .bar-value + pli-text {
                width: var(--text-content-width);
                padding-left: var(--size-1);
            }
        `,
    ];

    _successLayout(data: AlertsStatistics) {
        const legendLabels: {
            color: string;
            label: string;
            value: number;
            variant: PliDotVariant;
        }[] = [
            {
                variant: 'New',
                color: '#9EEAF9',
                label: 'New',
                value: data.new,
            },
            {
                variant: 'Opened',
                color: '#EFADCE',
                label: 'Opened',
                value: data.opened,
            },
            {
                variant: 'Escalated',
                color: '#F0FC62',
                label: 'Escalated',
                value: data.escalated,
            },
            {
                variant: 'Closed',
                color: '#343A40',
                label: 'Closed',
                value: data.closed,
            },
            {
                variant: 'FalsePositive',
                color: '#FECBA1',
                label: 'False Positive',
                value: data.falsePositive,
            },
        ];

        const sorted = legendLabels.sort((a, b) => b.value - a.value);
        const total = legendLabels.map((l) => l.value).reduce((sum, current) => sum + current, 0);
        const highestValue = sorted[0].value;
        const items = sorted
            .map((i) => ({
                ...i,
                percentage: (i.value / highestValue) * 100,
            }))
            .sort((a, b) => b.value - a.value);

        return html`
            <div class="grid items-center">
                <div class="col-span-4 col-lg-span-3">
                    <div class="chart">
                        <pli-donut-chart
                            .data="${[...legendLabels].map((m) => m.value)}"
                            .labels="${legendLabels.map((i) => i.label)}"
                            .colors="${legendLabels.map((l) => l.color)}"
                        >
                            <pli-text variant="h2">${total}</pli-text>
                        </pli-donut-chart>
                    </div>
                </div>
                <div class="col-span-8 col-lg-span-6">
                    <div class="bar-chart">
                        <pli-text variant="h2" as="h1">Alerts</pli-text>
                        ${items.map(
                            (i) => html`
                                <div class="bar-section" style="--bar-width: ${i.percentage}%; --bar-bg: ${i.color}">
                                    <pli-dot-label .variant="${i.variant}" .label="${i.label}"></pli-dot-label>
                                    <div class="bar">
                                        <div class="bar-value"></div>
                                        <pli-text variant="body">${i.value}</pli-text>
                                    </div>
                                </div>
                            `,
                        )}
                    </div>
                </div>
            </div>
        `;
    }

    _loadingLayout() {
        return html`
            <div class="graphics-grid">
                <div class="chart">
                    <pli-skeleton aspectRatio="1/1" borderRadius="full"></pli-skeleton>
                </div>
                <div class="bar-chart">
                    <pli-skeleton .height="${34}"></pli-skeleton>
                    ${map(range(5), (i) => html` <pli-skeleton .height="${28}"></pli-skeleton> `)}
                </div>
            </div>
        `;
    }

    render() {
        const { _loadingLayout, _successLayout, errorService } = this;

        return html`
            <pli-card padding="collapsed">
                <div class="grid-vertical gap-1">
                    ${this._todosTask.render({
                        pending: () => _loadingLayout(),
                        complete: (data) => _successLayout(data),
                        error: (e: Error) => errorService.showErrorDialog(e.message),
                    })}
                </div>
            </pli-card>
        `;
    }
}
