import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import Chart from 'chart.js/auto';
import { ErrorService } from 'error/error-service';
import { AlertStatus } from 'alert-status';
import { Status } from 'case-model';

@autoinject
export class Home {
    httpClient: HttpClient;
    alertsStatisticsCanvas: HTMLCanvasElement;
    alertsStatiticsChart: Chart<'doughnut', number[], string>;
    alertsStatistics: AlertsStatistics;
    statisticPeriod: string = 'SevenDays';
    statisticsItems: StatisticsItem[] = [];
    alerts: Alert[] = [];
    myAlerts: Alert[] = [];
    myCases: Case[] = [];
    cases: Case[] = [];
    errorService: ErrorService;

    constructor(httpClient: HttpClient, errorService: ErrorService) {
        this.httpClient = httpClient;
        this.errorService = errorService;
    }

    async activate() {
        let task1 = this.loadAlertsStatistics();
        // let task2 = this.loadStatistics();
        let task3 = this.loadAlerts();
        let task4 = this.loadCases();

        await task1;
        // await task2;
        await task3;
        await task4;

        await Promise.all([this.loadMyAlerts(), this.loadMyCases()]);
    }

    total(): number {
        return Object.values(this.alertsStatistics).reduce((sum, current) => sum + current, 0);
    }

    attached() {
        this.alertsStatiticsChart = new Chart(this.alertsStatisticsCanvas, {
            type: 'doughnut',
            data: {
                labels: ['New Alerts', 'Opened', 'Escalated', 'Closed', 'False Positive'],
                datasets: [
                    {
                        data: [
                            this.alertsStatistics.new,
                            this.alertsStatistics.opened,
                            this.alertsStatistics.escalated,
                            this.alertsStatistics.closed,
                            this.alertsStatistics.falsePositive,
                        ],
                        backgroundColor: ['#9EEAF9', '#EFADCE', '#F0FC62', '#343A40', '#FECBA1'],
                    },
                ],
            },
            options: {
                responsive: true,
                cutout: 90,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        });
    }

    collapsed: boolean = true;

    toggle() {
        this.collapsed = !this.collapsed;
    }

    async loadAlertsStatistics() {
        try {
            const response: any = await this.httpClient.get('dashboard/alerts-statistics');
            const json = await response.json();
            this.alertsStatistics = <AlertsStatistics>json;
        } catch (error) {
            this.errorService.showErrorDialog(error.message);
        }
    }

    async loadMyAlerts() {
        try {
            const response: any = await this.httpClient.get(`users/alerts`);
            const json = await response.json();
            this.myAlerts = <Alert[]>json.list;
        } catch (error) {
            this.errorService.showErrorDialog(error.message);
        }
    }

    async loadAlerts() {
        try {
            const statuses: AlertStatus[] = [AlertStatus.Escalated, AlertStatus.New, AlertStatus.Opened];
            const statusesQuery = statuses.join('&statuses=');
            const response: any = await this.httpClient.get(`alerts?statuses=${statusesQuery}`);
            const json = await response.json();
            this.alerts = <Alert[]>json.list;
        } catch (error) {
            this.errorService.showErrorDialog(error.message);
        }
    }

    async loadMyCases() {
        try {
            const response: any = await this.httpClient.get(`users/cases`);
            const json = await response.json();

            this.myCases = <Case[]>json.list;
        } catch (error) {
            this.errorService.showErrorDialog(error.message);
        }
    }

    async loadCases() {
        try {
            const statuses: Status[] = [Status.Investigating, Status.Reporting];
            const statusesQuery = statuses.join('&statuses=');
            const response: any = await this.httpClient.get(`cases?statuses=${statusesQuery}`);
            const json = await response.json();
            this.cases = <Case[]>json.list;
        } catch (error) {
            this.errorService.showErrorDialog(error.message);
        }
    }

    async onPeriodChanged(period: string) {
        this.toggle();
        this.statisticPeriod = period;
    }
}

class AlertsStatistics {
    new: number;
    opened: number;
    escalated: number;
    closed: number;
    falsePositive: number;
}

class StatisticsItem {
    title: string;
    description: string;
    value: number;
    delta: number;
}

class Alert {}

class Case {}
